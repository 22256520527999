import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Layout } from "../components";
import { getContent } from "../helper";
import { PuppiesThenAndNowLayout } from "../layouts/PuppiesThenAndNow";

const PuppiesThenAndNow = props => {
  const data = getContent(props?.data);

  return (
    <Layout title="Puppies Then And Now" {...props}>
      <Helmet>
        <meta
          name="description"
          content="Come see our gorgeous tiny Shih Tzu puppies grow right in front of your eyes! They say seeing is believing, and we believe you'll fall in love over and over as your Shih Tzu puppy grows!"
        />
        <meta
          name="keywords"
          content="Imperial Shih Tzu Puppies for Sale, shih tzu puppies for sale los angeles, shih tzu for sale san diego, Teacuo Shih Tzu Puppies For Sale, AKC Shih Tzu Puppies for sale, Shih Tzu Puppies for Sale, Tiny Shih Tzu Puppies for sale, Shih Tzu Puppies for sale in Fullerton, Shih Tzu Puppies for sale in Irvine, Shih Tzu Puppies for sale in Newport Beach, Shih Tzu Puppies for sale in Los Angeles, Shih Tzu Puppies for sale in Mission Viejo, Shih Tzu Puppies for sale in Lake Forest, Shih Tzu Puppies for sale in Brea, Shih Tzu Puppies For Sale in La Habra Heights, Shih Tzu Puppies for Sale in Costa Mesa, Shih Tzu Puppies for sale in Las Vegas, Shih Tzu Puppies for Sale in Anaheim Hills"
        />
      </Helmet>
      <PuppiesThenAndNowLayout {...data} />
    </Layout>
  );
};

export default PuppiesThenAndNow;

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/puppies-then-and-now/" } }
    ) {
      nodes {
        html
        frontmatter {
          title
          puppies_then_and_now {
            name
            slides {
              slide {
                picture
                description
              }
            }
          }
          contact
          content
        }
      }
    }
  }
`;
