import React from "react";

import "./style.scss";
import { getImage, parseToHtml } from "../../helper";
import { Contact } from "../../components";

export const PuppiesThenAndNowLayout = ({
  title = "",
  html = "",
  content = "",
  contact = "",
  puppies_then_and_now = [],
}) => {
  const puppies =
    puppies_then_and_now
      ?.map(item => item?.slides || [])
      ?.flat()
      ?.map(item => item?.slide) || [];

  return (
    <div className="puppiesThenAndNow">
      {title && <h1>{title}</h1>}
      {(content || html) && (
        <div className="data">{parseToHtml(content || html)}</div>
      )}
      {puppies?.length ? (
        <div className="puppies">
          {puppies?.map((puppy, index) => (
            <div className="puppy" key={index}>
              <img src={getImage(puppy?.picture)} alt={puppy?.description} />
              <p>{puppy?.description}</p>
            </div>
          ))}
        </div>
      ) : null}
      <h5>More pictures coming soon!</h5>
      <Contact image={contact} />
    </div>
  );
};
